import * as React from 'react';
import Modal from '@mui/material/Modal';
import debounce from '../../../utils/debounce';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Input,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import useUsuarioLocal from '../../../hooks/usuarioLocal.hook';
import ImageIcon from '@mui/icons-material/Image';
import ButtonCloseModal from '../../../components/ButtonCloseModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  height: '70%',
  overflowX: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListaDeUrgencia = ['BAIXA', 'MÉDIA'];

const initialNovoChamado = {
  tipo: '',
  urgencia: '',
  setor: '',
  titulo: '',
  descricao: '',
  solicitante: '',
  categoria: '',
  imagem: null,
  responsavel: '',
};

export default function XModalAbrirChamado({
  open,
  onClose,
  handleSubmit,
  listaSetores,
  listaDeTipo,
  listaDeCategoria,
}) {
  const [formData, setFormData] = React.useState(initialNovoChamado);
  const [outrosCategoria, setOutrosCategoria] = React.useState(false);
  const { email } = useUsuarioLocal();

  const [setorSelecionado, setSetorSelecionado] = useState('');
  const [emailSelecionado, setEmailSelecionado] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'idSetor_Demandado' ? Number(value) : value,
      solicitante: email,
      setorResponsavel: setorSelecionado,
      responsavel: emailSelecionado,
    }));
  };


  const cancelFormData = () => {
    setFormData(initialNovoChamado);
    setOutrosCategoria(false);
    onClose();
  };

  const handleSubmitFormData = (e) => {
    e.preventDefault(); 
    console.log(formData); 

    if (!formData.titulo) {
      console.error('O título não está definido');
      return; 
    }

    handleSubmit(formData); 
    setFormData(initialNovoChamado); 
    setOutrosCategoria(false);
    setSetorSelecionado('');
    onClose(); 
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ButtonCloseModal onClick={cancelFormData} />
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Registrar Chamado
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginBottom: 2 }}
          >
            Solicitante: {email}
          </Typography>
          <form onSubmit={handleSubmitFormData}>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Para qual setor você quer abrir o chamado?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                name="setor"
                value={setorSelecionado}
                onChange={(e) => {
                  setFormData({ ...formData, setor: e.target.value });
                  setSetorSelecionado(e.target.value);
                }}
                sx={{
                  border: 1,
                }}
              >
                {listaSetores.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.setor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tipo"
                size="small"
                value={formData.tipo}
                onChange={handleChange}
                sx={{
                  border: 1,
                }}
              >
                {listaDeTipo.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                name="setor"
                value={formData.categoria}
                onChange={(e) => {
                  setFormData({ ...formData, categoria: e.target.value });
                }}
                sx={{
                  border: 1,
                }}
              >
                {listaDeCategoria.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={formData.categoria === ''}
            >
              <InputLabel id="demo-simple-select-label">Urgência</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={formData.urgencia}
                name="urgencia"
                onChange={handleChange}
              >
                {ListaDeUrgencia.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required>
              <TextField
                id="filled-basic"
                label="Título"
                variant="filled"
                inputProps={{ maxLength: 45 }}
                name="titulo"
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl fullWidth required>
              <TextField
                variant="filled"
                id="outlined-multiline-static"
                label="Descrição"
                size="small"
                placeholder="Descreva com todos os detalhes possíveis e claros"
                multiline
                rows={3}
                disabled={formData.titulo.length < 7}
                required
                onChange={(e) =>
                  debounce(() => {
                    setFormData({ ...formData, descricao: e.target.value });
                  })
                }
              />
            </FormControl>
            <InputLabel htmlFor="file-input">
              <input
                accept="image/jpeg, image/png"
                id="file-input"
                size="small"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    imagem: e.target.files[0],
                  })
                }
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  alignItems: 'center',
                  marginTop: 2,
                }}
              >
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<UploadFileIcon />}
                >
                  Escolher Arquivo
                </Button>
                {formData.imagem && (
                  <Typography variant="body2">
                    <ImageIcon /> {formData.imagem.name}
                  </Typography>
                )}
              </Box>
            </InputLabel>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
                color: '#f00',
                visibility:
                  formData.titulo.length < 7 || formData.descricao < 15
                    ? 'visible'
                    : 'hidden',
              }}
            >
              Todos os campos são obrigatórios
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, paddingY: 2 }} fullWidth>
              <Button
                variant="contained"
                color="success"
                endIcon={<SendIcon />}
                fullWidth
                type="submit"
                onClick={(e) => handleSubmitFormData(e)}
                disabled={formData.titulo.length < 7 || formData.descricao < 15}
              >
                Enviar
              </Button>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={cancelFormData}
                type="reset"
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
